<script setup>
import { storeToRefs } from 'pinia';
import { useSideBar } from '~/stores/sideBar';

// Global
const sideBarStore = useSideBar();
const { sideBarLayerOpen } = storeToRefs(sideBarStore);
</script>

<template>
    <Transition name="fade">
        <div v-if="sideBarLayerOpen" class="side-bar-onboarding-layer" />
    </Transition>
    <Transition name="fade-to-right">
        <LazyUserAccountOnBoardingForm v-if="sideBarLayerOpen" />
    </Transition>
</template>

<style lang="scss">
.side-bar-onboarding-layer {
    background: $side-bar-onboarding-layer;
    position: fixed;
    inset: 0;
    z-index: z('side-bar-onboarding-layer');
}
</style>
